import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Loading from "./Components/loading/Loading";
import { useSelector } from "react-redux";

const Layout = lazy(() => import("./Pages/Layout/Layout"));
const MainPage = lazy(() => import("./Pages/MainPage/MainPage"));
const Advertising = lazy(() => import("./Pages/advertising/Advertising"));
const Order = lazy(() => import("./Pages/Order/Order"));
const Information = lazy(() => import("./Pages/Information/Information"));
const Card = lazy(() => import("./Pages/card/Card"));
const Page404 = lazy(() => import("./Pages/page404/Page404"));
const PrintPdfCheck = lazy(() => import("./Pages/printPdfCheck/PrintPdfCheck"));
const CompanyPage = lazy(() => import("./Pages/companyPage/CompanyPage"));
const OrderPaid = lazy(() => import("./Pages/orderPaid/OrderPaid"));
const OrderNotPaid = lazy(() => import("./Pages/orderNotPaid/OrderNotPaid"));
const PrivacyPolicy = lazy(() => import("./Pages/PrivacyPolicy"));

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loading />}>
              <Layout />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<Loading />}>
                <MainPage />
              </Suspense>
            }
          />
          <Route
            path="/order"
            element={
              <Suspense fallback={<Loading />}>
                <Order />
              </Suspense>
            }
          />
          <Route
            path="/information"
            element={
              <Suspense fallback={<Loading />}>
                <Information />
              </Suspense>
            }
          />
          <Route
            path="/advertising"
            element={
              <Suspense fallback={<Loading />}>
                <Advertising />
              </Suspense>
            }
          />
          <Route
            path="/card/:slug"
            element={
              <Suspense fallback={<Loading />}>
                <Card />
              </Suspense>
            }
          />
          <Route
            path="/printpdfcheck"
            element={
              <Suspense fallback={<Loading />}>
                <PrintPdfCheck />
              </Suspense>
            }
          />
          <Route
            path="/company/:merchant_id"
            element={
              <Suspense fallback={<Loading />}>
                <CompanyPage />
              </Suspense>
            }
          />
          <Route
            path="/order_paid/:id"
            element={
              <Suspense fallback={<Loading />}>
                <OrderPaid />
              </Suspense>
            }
          />
          <Route
            path="/order_not_paid/:id"
            element={
              <Suspense fallback={<Loading />}>
                <OrderNotPaid />
              </Suspense>
            }
          />
          <Route
            path="/privacyPolicy"
            element={
              <Suspense fallback={<Loading />}>
                <PrivacyPolicy />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Suspense fallback={<Loading />}>
                <Page404 />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
