import { Progress, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';

function Loading() {
  return (
    <div className='w-full h-screen flex justify-center items-center fixed top-0 left-0 z-50'>
      <Spin size='large'/>
    </div>
  )
}

export function Loader() {
  const [loading, setLoading] = useState(true);
  setTimeout(()=>setLoading(false),1000)
  return (
  <div className={`${loading ? 'block' : 'hidden'} fixed top-0 left-0 w-full h-[5px] bg-white z-[999999]`}>
    <div className={`bg-green-400 h-[5px] loader_width`}></div>
  </div>
  );
}

export default Loading