import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const globalRequest = createApi({
  reducerPath: "globalRequest",
  tagTypes: ["CARDS"],
  baseQuery: fetchBaseQuery({ baseUrl: "https://edonish.market/api/v1/" }),
  endpoints: (build) => ({
    getCardsLimit: build.query({
      query: ({ limit, title }) =>
        `/items/${title != "" ? `?q=${title}` : `?offset=0&limit=${limit}`}`,
      providesTags: ["CARDS"],
    }),
    getCards: build.query({
      query: () => `items/marketplace/all`,
      providesTags: ["CARDS"],
    }),
    getArea: build.query({
      query: () => `/commons/areas`,
      providesTags: ["CARDS"],
    }),
    getCategory: build.query({
      query: (obj) => `commons/categories`,
      providesTags: ["CARDS"],
    }),
    getDeliveryTypes: build.query({
      query: () => `/commons/delivery_types`,
      providesTags: ["CARDS"],
    }),
    getBanner: build.query({
      query: (type) => `/promotions/marketplace/banner/`,
      providesTags: ["CARDS"],
    }),
    // postOrder: build.mutation({
    //     query:(body) => ({
    //         method:"POST",
    //         url:'/payment',
    //         body,
    //     })
    // })
  }),
});
export const {
  useGetAreaQuery,
  useGetCardsLimitQuery,
  useGetCategoryQuery,
  useGetCardsQuery,
  useGetDeliveryTypesQuery,
  useGetBannerQuery,
} = globalRequest;

export default globalRequest;
