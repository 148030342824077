import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_KEY } from "../../GlobalFunctions";
import {
  setOrderPaymentModal,
  setOrderStatusShowOrder,
} from "./stateLocallhost";
import { message } from "antd";

export const getCardsLimitOrSearch = createAsyncThunk(
  "getCardsLimitOrSearch",
  async (
    {
      limit = null,
      offset = 0,
      title = null,
      cardsLimitState,
      category_id = null,
      parent_id = null,
      typeCardsWithDate,
      typeCardsWithPrice,
      merchant_id = null,
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      if (cardsLimitState) {
        // const { data } = await axios.get(`${ title ? `q=${title}` : `offset=0&limit=${limit}${ category_id ? `&category_id=${category_id}` : "" }${ parent_id ? `&parent_id=${parent_id}` : "" }`}${ typeCardsWithDate?.id ? `&${typeCardsWithDate.value}` : ''}${ typeCardsWithPrice?.id ? `&${typeCardsWithPrice.value}` : '' }`);
        const { data } =
          await axios.get(`https://edonish.market/api/v1/items/marketplace/all/?${
            title
              ? `q=${title}`
              : `${limit ? `offset=${offset}&limit=${limit}` : ""}${
                  category_id ? `&category_id=${category_id}` : ""
                }${parent_id ? `&parent_id=${parent_id}` : ""}`
          }${typeCardsWithDate?.id ? `&${typeCardsWithDate.value}` : ""}${
            typeCardsWithPrice?.id ? `&${typeCardsWithPrice.value}` : ""
          }${merchant_id ? `&merchant_id=${merchant_id}` : ""}
        `);
        if (title) {
          dispatch(setSearchState(true));
        }
        return { data, limit, offset, title, category_id, parent_id };
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const postOrderNumber = createAsyncThunk(
  "postOrderNumber",
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `https://edonish.market/api/v1/payment`,
        body
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const postObjectOrder = createAsyncThunk(
  "postObjectOrder",
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `https://edonish.market/ordersapi/order/`,
        body
      );
      if (body.payment_method != "783a5ed6-c5c1-4987-af88-0bf5cb3bc08a") {
        dispatch(setOrderStatusShowOrder(data));
        dispatch(setOrderPaymentModal(true));
      } else {
        window.location.href = data.payment_url;
      }
      return data;
    } catch (error) {
      message.error(error.response.statusText);
      return rejectWithValue(error.message);
    }
  }
);

export const getOrderPaymentStatus = createAsyncThunk(
  "getOrderPaymentStatus",
  async (props, { dispatch, rejectWithValue }) => {
    try {
      const response = await fetch(
        `https://smartpay.tj/subapi/payler/order_payment_status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json",
            "x-app-token": API_KEY(),
          },
          body: JSON.stringify({
            invoice_number: props.params.invoiceId,
          }),
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        if (data.items.find((elem) => elem.merchant_tin === undefined)) {
          dispatch(getOrderPaymentStatus(props));
        }
        return data;
      } else {
        dispatch(setRequestFailed(true));
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const registerUser = createAsyncThunk(
  "registerUser",
  async (
    { givenName, givenEmail, givenPhone, socialNet },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.post(
        `https://edonish.market/api/v1/promotions/subscribe`,
        {
          phone_number: givenPhone,
          full_name: givenName,
          email: givenEmail,
          messanger_name: socialNet,
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
//pi/v1/analytics/views
export const analyticView = createAsyncThunk(
  "registerUser",
  async ({ item_id }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `https://edonish.market/api/v1/analytics/views?item_id=${item_id}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getDelivery = createAsyncThunk(
  "getDelivery",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `https://edonish.market/deliveryapi/delivery/all/`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getBanner = createAsyncThunk(
  "getBanner",
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      const { data } = await axios.get(
        `https://edonish.market/api/v1/promotions/marketplace/banner/`
      );
      for (let i = 0; i < data.length; i++) {
        dispatch(getCardById({ item_id: data[i].item_id, banner: true }));
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getReasons = createAsyncThunk(
  "getReasons",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        "https://edonish.market/ordersapi/order/backorder-reasons/"
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPaymentMethods = createAsyncThunk(
  "getPaymentMethod",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        "https://edonish.market/ordersapi/payment/payment_methods/"
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const paymentSuccess = createAsyncThunk(
  "paymentSuccess",
  async ({ id, navigate }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.post(
        `https://edonish.market/ordersapi/payment/success/?invoice_number=${id}`
      );
      if (data.order_number) {
        dispatch(setOrderStatusShowOrder(data));
      }
      return data;
    } catch (error) {
      navigate("/");
      return rejectWithValue(error.message);
    }
  }
);

export const getCardById = createAsyncThunk(
  "getCardById",
  async (
    {
      item_id = null,
      slug = null,
      barcode = null,
      sku = null,
      banner = null,
      userCart,
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.get(
        `https://edonish.market/api/v1/items/marketplace/item?${
          item_id
            ? `item_id=${item_id}`
            : slug
            ? `slug=${slug}`
            : barcode
            ? `&barcode=${barcode}`
            : sku
            ? `sku=${sku}`
            : ""
        }`
      );
      return { data, banner, userCart };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const globalState = createSlice({
  name: "states",
  initialState: {
    openFilterCategoryModel: false,
    cardModel: { card: {}, open: false },
    cardMessageId: 0,
    cardsLoading: false,
    bannerLoading: false,
    cardsRejected: null,
    categoryFilter: null,
    categoryStateId: null,

    categoryLimit: 3,
    prevSearchText: "",
    cardsFilter: [],
    userOrder: null,
    requestFailed: null,
    receiptLoading: false,
    success: false,
    isRegistered: false,

    cardsLimit: 20,
    cardsOffset: 0,
    cardsLimitState: true,
    searchState: false,
    imageModalDesctopCard: null,
    typeCardsWithDate: {
      id: 1,
      value: "sort_column=created_at&sort_direction=DESC",
    },
    typeCardsWithPrice: { id: null, value: null },
    /////////////////////////////////////////
    selectFilterState: null, //1
    bodyMobilHiddenState: false,
    refreshSearch: null,
    // modals state
    openDeliveryModal: false,
    openOrderPaymentMessageModal: false,
    openReturnModal: false,
    // data
    dataCardsLimitOrSearch: {},
    dataBanner: [],
    dataBannerPrice: [],
    dataDelivery: [],
    dataCards: {},
    dataSearch: {},
    dataReceipt: null,
    dataReasons: [],
    dataPaymentMethods: [],
    deleteOrder: null,
    dataCardById: {},
    dataCartCards: [],
    companyPageItem: {},
    merchant_id: null,
  },
  reducers: {
    setCardModel: (state, action) => {
      state.cardModel = action.payload;
    },
    setDataCards: (state, action) => {
      state.dataCards = action.payload;
    },
    setCategoryLimit: (state, action) => {
      state.categoryLimit = action.payload;
    },
    setCategoryFilter: (state, action) => {
      state.categoryFilter = action.payload;
    },
    setPrevSearchText: (state, action) => {
      state.prevSearchText = action.payload;
    },
    setOpenFilterCategoryModel: (state, action) => {
      state.openFilterCategoryModel = action.payload;
    },
    setCardsFilter: (state, action) => {
      state.cardsFilter = action.payload;
    },
    setUserOrder: (state, action) => {
      state.userOrder = action.payload;
    },
    setRequestFailed: (state, action) => {
      state.requestFailed = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    post: (state, action) => {
      let { payload } = action;
      state.cardMessageId++;
      payload.obj.id = state.cardMessageId;
      state.cards.map((e) => {
        if (e.id == payload.id) {
          e.messages.push(payload.obj);
        }
      });
    },

    setCardsLimit: (state, action) => {
      state.cardsLimit = action.payload;
    },
    setCardsOffset: (state, action) => {
      state.cardsOffset = action.payload;
    },
    setCardLimitState: (state, action) => {
      state.cardsLimitState = action.payload;
    },
    setImageModalDesctopCard: (state, action) => {
      state.imageModalDesctopCard = action.payload;
    },
    setCategoryStateId: (state, action) => {
      state.categoryStateId = action.payload;
    },
    setTypeCardsWithDate: (state, action) => {
      state.typeCardsWithDate = action.payload;
    },
    setTypeCardsWithPrice: (state, action) => {
      state.typeCardsWithPrice = action.payload;
    },
    setSelectFilterState: (state, action) => {
      state.selectFilterState = action.payload;
    },
    setIsRegistered: (state, action) => {
      state.isRegistered = action.payload;
    },
    setBodyMobilHiddenState: (state, action) => {
      state.bodyMobilHiddenState = action.payload;
    },
    setSearchState: (state, action) => {
      state.searchState = action.payload;
    },
    setRefreshSearch: (state, action) => {
      state.refreshSearch = Math.random();
    },
    setOpenDeliveryModal: (state, action) => {
      state.openDeliveryModal = action.payload;
    },
    setOpenOrderPaymentMessageModal: (state, action) => {
      state.openOrderPaymentMessageModal = action.payload;
    },
    setOpenReturnModal: (state, action) => {
      state.openReturnModal = action.payload;
    },
    setDeleteOrder: (state, action) => {
      state.deleteOrder = action.payload;
    },
    resetAllCards: (state, action) => {
      state.dataCardsLimitOrSearch = {};
    },
    setCompanyPageItem: (state, action) => {
      state.companyPageItem = action.payload;
    },
    setMerchand_id: (state, action) => {
      state.merchant_id = action.payload;
    },
  },
  extraReducers: {
    [postObjectOrder.pending]: (state, action) => {
      state.cardsLoading = true;
      state.cardsRejected = null;
    },
    [postObjectOrder.fulfilled]: (state, action) => {
      state.orderPaymentModal = true;
      state.cardsLoading = false;
    },
    [postObjectOrder.rejected]: (state, action) => {
      state.cardsRejected = action.payload;
      state.cardsLoading = false;
    },
    [getOrderPaymentStatus.pending]: (state, action) => {
      state.receiptLoading = true;
      state.cardsRejected = null;
    },
    [getOrderPaymentStatus.fulfilled]: (state, action) => {
      state.receiptLoading = false;
      state.dataReceipt = action.payload;
    },
    [getOrderPaymentStatus.rejected]: (state, action) => {
      state.receiptLoading = false;
      state.cardsRejected = action.payload;
    },
    [getCardsLimitOrSearch.pending]: (state, action) => {
      state.cardsLoading = true;
      state.cardsRejected = null;
    },
    [getCardsLimitOrSearch.fulfilled]: (state, action) => {
      state.cardsLoading = false;
      state.cardsLimitState = false;
      if (action.payload && action.payload.data.items) {
        const { data, limit, offset, title, category_id, parent_id } =
          action.payload;
        if (title || offset == 0 || (offset == 0 && parent_id)) {
          state.dataCardsLimitOrSearch = data;
        } else {
          const dataCards = state.dataCardsLimitOrSearch.items;
          state.dataCardsLimitOrSearch.items = [...dataCards, ...data?.items];
          // state.dataCardsLimitOrSearch.items = [...dataCards, ...data?.items?.filter((el) => !dataCards.find((elem) => elem.item_id == el.item_id))];
          state.dataCardsLimitOrSearch.number_of_items = data?.number_of_items;
        }
      }
    },
    [getCardsLimitOrSearch.rejected]: (state, action) => {
      state.cardsLoading = false;
      state.cardsRejected = action.payload;
    },
    [getDelivery.pending]: (state, action) => {
      state.cardsLoading = true;
      state.cardsRejected = null;
    },
    [getDelivery.fulfilled]: (state, action) => {
      state.cardsLoading = false;
      state.dataDelivery = action.payload;
    },
    [getDelivery.rejected]: (state, action) => {
      state.cardsLoading = false;
      state.cardsRejected = action.payload;
    },
    [getBanner.pending]: (state, action) => {
      state.bannerLoading = true;
      state.cardsRejected = null;
    },
    [getBanner.fulfilled]: (state, action) => {
      state.bannerLoading = false;
      state.dataBanner = action.payload;
    },
    [getBanner.rejected]: (state, action) => {
      state.bannerLoading = false;
      state.cardsRejected = action.payload;
    },
    [getReasons.pending]: (state, action) => {
      state.cardsLoading = true;
    },
    [getReasons.fulfilled]: (state, action) => {
      state.cardsLoading = false;
      state.dataReasons = action.payload;
    },
    [getReasons.rejected]: (state, action) => {
      state.cardsLoading = false;
    },
    [getPaymentMethods.pending]: (state, action) => {
      state.cardsLoading = true;
    },
    [getPaymentMethods.fulfilled]: (state, action) => {
      state.cardsLoading = false;
      state.dataPaymentMethods = action.payload;
    },
    [getPaymentMethods.rejected]: (state, action) => {
      state.cardsLoading = false;
    },
    [paymentSuccess.pending]: (state, action) => {
      state.cardsLoading = true;
    },
    [paymentSuccess.fulfilled]: (state, action) => {
      state.cardsLoading = false;
      state.deleteOrder = true;
    },
    [paymentSuccess.rejected]: (state, action) => {
      state.cardsLoading = false;
    },
    [getCardById.pending]: (state, action) => {
      state.cardsLoading = true;
    },
    [getCardById.fulfilled]: (state, action) => {
      state.cardsLoading = false;
      if (action.payload.banner) {
        state.dataBannerPrice.push(action.payload.data);
      } else if (action.payload.userCart) {
        state.dataCartCards.push(action.payload.data);
      } else {
        state.dataCardById = action.payload.data;
      }
    },
    [getCardById.rejected]: (state, action) => {
      state.cardsLoading = false;
    },
  },
});

export const {
  post,
  setUserOrder,
  setCardModel,
  setDataCards,
  setCategoryLimit,
  setCategoryFilter,
  setPrevSearchText,
  setOpenFilterCategoryModel,
  setCardsFilter,
  setRequestFailed,
  setSuccess,

  setCardsLimit,
  setCardsOffset,
  setCardLimitState,
  setImageModalDesctopCard,
  setCategoryStateId,
  setTypeCardsWithDate,
  setTypeCardsWithPrice,
  setSelectFilterState,
  setIsRegistered,
  setBodyMobilHiddenState,
  setSearchState,
  setRefreshSearch,
  setOpenDeliveryModal,
  setOpenOrderPaymentMessageModal,
  setOpenReturnModal,
  setDeleteOrder,
  resetAllCards,
  setCompanyPageItem,
  setMerchand_id,
} = globalState.actions;

export default globalState.reducer;
