import React, { Component } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import {
  setMobileFooterValue,
  setOrderStatusShowOrder,
  setUser,
} from "./apiRedux/reducers/stateLocallhost";
import {
  setUserOrder,
  setCardModel,
} from "./apiRedux/reducers/globalState";

export function numberWithSpaces(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

export const withRouter = (WrappedComponent) => (props) => {
  const params = useParams();
  const { t } = useTranslation("", "", "", { useSuspense: false });

  return <WrappedComponent {...props} params={params} translation={t} />;
};

export function API_KEY() {
  const d = new Date();
  const time = (d.getTime() / 1000).toFixed(0);

  var iv = CryptoJS.lib.WordArray.random(16);
  //var key = CryptoJS.enc.Utf8.parse( 'secret' + time );
  var key = CryptoJS.enc.Base64.parse("aR1h7EefwlPNVkvTHwfs6w==");

  var encrypted = CryptoJS.AES.encrypt(
    "5fe322089e65f10c4f82e6aee55ba4f095012a90afcca9c97098325695107d40" + time,
    key,
    { iv: iv }
  );
  var joinedData = iv.clone().concat(encrypted.ciphertext);
  var joinedDataB64 = CryptoJS.enc.Base64.stringify(joinedData);
  return joinedDataB64;
}

export const useExit = () => {
  const dispatch = useDispatch();

  const exit = () => {
    dispatch(setUserOrder(null));
    dispatch(setOrderStatusShowOrder({}))
    dispatch(
      setUser({
        yesOrNo: true,
        user: {
          cart: [],
        },
      })
    );
  };
  return exit;
};

export const useGetCart = () => {
  const user = useSelector(({ stateLocallhost }) => stateLocallhost.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const success = useSelector(({ globalState }) => globalState.success);

  const getCart = (obj, yes = null) => {
    if (yes && user.user.cart.find((e) => e.card?.item_id == obj.item_id)) {
      dispatch(setCardModel({ card: {}, open: false }));
      dispatch(setMobileFooterValue(2));
      // notify();
      navigate("/order");
    } else if (
      yes &&
      !user.user.cart.find((e) => e.card?.item_id == obj.item_id)
    ) {
      let card = { card: obj, amount: 1 };
      let body = {
        ...user.user,
        cart: [...user.user.cart, card],
      };
      dispatch(setUser({ yesOrNo: true, user: body }));
      dispatch(setCardModel({ card: {}, open: false }));
      dispatch(setMobileFooterValue(2));
      navigate("/order");
    } else if (!user.user.cart.find((e) => e.card?.item_id == obj?.item_id)) {
      let card = { card: obj, amount: 1 };
      let body = {
        ...user.user,
        cart: [...user.user.cart, card],
      };
      dispatch(setUser({ yesOrNo: true, user: body }));
    } else {
      let body = {
        ...user.user,
        cart: user.user.cart.filter((e) => e.card?.item_id != obj?.item_id),
      };
      dispatch(setUser({ yesOrNo: true, user: body }));
    }
  };
  return getCart;
};

export const usePostCard = () => {
  const user = useSelector(({ stateLocallhost }) => stateLocallhost.user);
  const dispatch = useDispatch();
  const postCard = (obj) => {
    let userIndexCard;
    user?.user?.cart?.map((arr, i) => {
      if (arr?.card?.item_id == obj?.item_id) {
        userIndexCard = i;
      }
    });
    const userObjCard = user?.user?.cart?.find(
      (arr) => arr?.card?.item_id == obj?.item_id
    );
    if (obj?.item_quantity <= userObjCard.amount) {
      return 0;
    }
    let cart = [...user.user.cart];
    cart.splice(userIndexCard, 1, {
      ...userObjCard,
      amount: userObjCard.amount + 1,
    });
    const body = {
      ...user.user,
      cart,
    };
    dispatch(setUser({ user: body, yesOrNo: true }));
  };
  return postCard;
};

export const useDeleteOneCart = () => {
  const user = useSelector(({ stateLocallhost }) => stateLocallhost.user);
  const dispatch = useDispatch();
  const deleteCard = useDeleteCard();
  const deleteOneCart = (obj) => {
    let userIndexCard;
    user?.user?.cart?.map((arr, i) => {
      if (arr?.card?.item_id == obj?.item_id) {
        userIndexCard = i;
      }
    });
    const userObjCard = user?.user?.cart?.find(
      (arr) => arr?.card?.item_id == obj?.item_id
    );
    if (userObjCard.amount <= 1) {
      deleteCard(userIndexCard);
      return 0;
    }
    let cart = [...user.user.cart];
    cart.splice(userIndexCard, 1, {
      ...userObjCard,
      amount: userObjCard.amount - 1,
    });
    const body = {
      ...user.user,
      cart,
    };
    dispatch(setUser({ user: body, yesOrNo: true }));
  };
  return deleteOneCart;
};

export const useDeleteCard = () => {
  const user = useSelector(({ stateLocallhost }) => stateLocallhost.user);
  const dispatch = useDispatch();
  const deleteCard = (index) => {
    let cart = [...user.user.cart];
    cart.splice(index, 1);
    const body = {
      ...user.user,
      cart,
    };
    dispatch(setUser({ user: body, yesOrNo: true }));
  };
  return deleteCard;
};

export const goBack = (num) => {
  for(let i =0; i< num; i++){
    window.history.back();
  }
}

export default {
  numberWithSpaces,
  withRouter,
  useExit,
  useGetCart,
  usePostCard,
  useDeleteOneCart,
  useDeleteCard,
};
