import { createSlice } from "@reduxjs/toolkit";

const stateLocallhost = createSlice({
  name: "stateLocallhost",
  initialState: {
    user: {
      yesOrNo: true,
      user: {
        cart: [],
      },
    },
    language: "ru",
    mobileFooterValue: 0,
    katalogMobileModel: false,
    otherMobileModel: false,
    darkModeState: localStorage.theme === "dark" ? "light" : "dark",
    orderPaymentModal: false,
    orderStatusShowOrder: { order: [], time: null },
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setMobileFooterValue: (state, action) => {
      state.mobileFooterValue = action.payload;
    },
    setKatalogModileModel: (state, action) => {
      state.katalogMobileModel = action.payload;
    },
    setOtherModileModel: (state, action) => {
      state.otherMobileModel = action.payload;
    },
    setDarkModeState: (state, action) => {
      state.darkModeState = action.payload;
    },
    setOrderPaymentModal: (state, action) => {
      state.orderPaymentModal = action.payload;
    },
    setOrderStatusShowOrder: (state, action) => {
      state.orderStatusShowOrder = action.payload;
    },
  },
});

export const {
  setUser,
  setUserOrder,
  setLanguage,
  setMobileFooterValue,
  setKatalogModileModel,
  setOtherModileModel,
  setDarkModeState,
  setOrderPaymentModal,
  setOrderStatusShowOrder,
  setCompanyLogoUrl,
} = stateLocallhost.actions;

export default stateLocallhost.reducer;
